import { nftmarketaddress, nftaddress } from '../config'
import Market from '../contracts/Market.sol/NFTMarket.json'
import NFT from '../contracts/NFT.sol/NFT.json'
import { useState } from 'react'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import { useNavigate } from 'react-router-dom'
import {Form,Input,Image,Button} from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import Web3Modal from 'web3modal'
const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')
const CreateNFT = () => {
  const [fileUrl, setFileUrl] = useState(null)
  const [formInput, updateFormInput] = useState({ price: '', name: '', description: '' })
  const { TextArea } = Input;
  const navigate = useNavigate();

  async function onChange(e) {
    const file = e.target.files[0]
    try {
      const added = await client.add(
        file,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      setFileUrl(url)
    } catch (error) {
      console.log('Error uploading file: ', error)
    }  
  }
  async function createMarket() {
    const { name, description, price } = formInput
    if (!name || !description || !price || !fileUrl) return
    /* first, upload to IPFS */
    const data = JSON.stringify({
      name, description, image: fileUrl
    })
    try {
      const added = await client.add(data)
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      createSale(url)
    } catch (error) {
      console.log('Error uploading file: ', error)
    }  
  }

  async function createSale(url) {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)    
    const signer = provider.getSigner()
    
    /* next, create the item */
    let contract = new ethers.Contract(nftaddress, NFT.abi, signer)
    let transaction = await contract.createToken(url)
    let tx = await transaction.wait()
    let event = tx.events[0]
    let value = event.args[2]
    let tokenId = value.toNumber()

    const price = ethers.utils.parseUnits(formInput.price, 'ether')
  
    /* then list the item for sale on the marketplace */
    contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
    let listingPrice = await contract.getListingPrice()
    listingPrice = listingPrice.toString()

    transaction = await contract.createMarketItem(nftaddress, tokenId, price, { value: listingPrice })
    await transaction.wait()
    navigate('/')
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      align="center"
    >
      <Form.Item label="NFT名字">
        <Input placeholder="请输入创作NFT名字" onChange={e => updateFormInput({ ...formInput, name: e.target.value })} />
      </Form.Item>
      <Form.Item label="资产描述">
        <TextArea showCount maxLength={500}
          style={{ height: 200 }}
          placeholder="NFT资产描述"
          onChange={e => updateFormInput({ ...formInput, description: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="出售价格">
        <Input placeholder="请输入出售NFT资产价格(以ETH计算)"
          style={{
            width: '100%',
          }}
          onChange={e => updateFormInput({ ...formInput, price: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="上传资产文件">
        <Input 
          type="file"
          name="Asset"
          onChange={onChange} 
        />
        {
          fileUrl && (
            <Image width="100%" src={fileUrl} />
          )
        }
      </Form.Item>
      
      <Form.Item label="创建并出售NFT">
        <Button 
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
          size="large"
          onClick={createMarket}
        >
            创作NFT资产
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CreateNFT