import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Web3Modal from "web3modal"
import {Typography,Image,Card,Space} from 'antd'
import {Link} from 'react-router-dom'
import Loader from './Loader'

import { nftmarketaddress, nftaddress } from '../config'

import Market from '../contracts/Market.sol/NFTMarket.json'
import NFT from '../contracts/NFT.sol/NFT.json'

const DashBoard = () => {
  const [nfts, setNfts] = useState([])
  const [sold, setSold] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {
    const web3Modal = new Web3Modal({
      network: "mainnet",
      cacheProvider: true,
    })
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()
      
    const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const data = await marketContract.fetchItemsCreated()
    
    const items = await Promise.all(data.map(async i => {
      const tokenUri = await tokenContract.tokenURI(i.tokenId)
      const meta = await axios.get(tokenUri)
      let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
      let item = {
        price,
        tokenId: i.tokenId.toNumber(),
        seller: i.seller,
        owner: i.owner,
        sold: i.sold,
        image: meta.data.image,
      }
      return item
    }))
    /* create a filtered array of items that have been sold */
    const soldItems = items.filter(i => i.sold)
    setSold(soldItems)
    setNfts(items)
    setLoadingState('loaded') 
  }
  if(loadingState === 'not-loaded') return <Loader />
  else if (loadingState === 'loaded' && !nfts.length) {
    return (
      <div style={{justifyContent: 'center', textAlign: 'center' , alignItems: 'center' , width:"100%"}}>
        <Typography.Title level={3} style={{color: 'grey', textAlign: 'center' }}>
          目前您还没有创建NFT资产，请先到 
          <Link to="/create-nft">
            &nbsp;创建NFT资产.
          </Link> <br />
          开启您的NFT创作交易之旅！
        </Typography.Title>
        <Image
          width={'60%'}
          src="https://gateway.pinata.cloud/ipfs/QmdN4iTJibznDwBgNbB3CxE1hGZYWLCDYfDAW3tkPwxhrx"
        />
      </div>
    )
  }
  return (
    <>
      <Space direction="vertical" size="large">
        <>
          <Typography.Title level={3} style={{color: 'grey', textAlign: 'center' }}>已创建NFT</Typography.Title>
          <Space size="large" wrap style={{justifyContent: 'center' }}>
            {
              nfts.map((nft, i) => (
              <Card
                key={i}
                hoverable
                style={{ width: 300 }}
                cover={<Image alt="NFT" src={nft.image} style={{ width: '100%',height:300 }} />}
              >
                <Card bordered={false} style={{background: "white" , width:"100%"}}>
                  <p style={{color: "grey" , fontSize: 18 , fontWeight:"bold" , textAlign: 'center'}}>价格:{nft.price} ETH</p>
                </Card>
              </Card>
                ))
            }
          </Space>
        </>
        <>
          <Typography.Title level={3} style={{color: 'grey', textAlign: 'center' }}>已出售NFT</Typography.Title>
          <Space size="large" wrap style={{justifyContent: 'center', width:'100%' }}>
            {
              Boolean(sold.length) && (
                <>
                  {
                    sold.map((nft, i) => (
                      <Card
                        key={i}
                        hoverable
                        style={{ width: 300 }}
                        cover={<Image alt="NFT" src={nft.image} style={{ width: '100%',height:300 }} />}
                      >
                        <Card bordered={false} style={{background: "white" , width:"100%"}}>
                          <p style={{color: "grey" , fontSize: 18 , fontWeight:"bold" , textAlign: 'center'}}>价格:{nft.price} ETH</p>
                        </Card>
                      </Card>
                    ))
                  }
                </>
              )
            }
          </Space>
        </>
      </Space>
    </>
  )
}

export default DashBoard