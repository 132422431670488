import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Web3Modal from "web3modal"
import {Typography,Image,Card,Space,Button} from 'antd'
import { TransactionOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom'
import Loader from './Loader'

import { nftaddress, nftmarketaddress } from '../config'

import NFT from '../contracts/NFT.sol/NFT.json'
import Market from '../contracts/Market.sol/NFTMarket.json'

const HomePage = () => {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const { Meta } = Card
  useEffect(() => {
    loadNFTs()
  }, [])
  async function loadNFTs() {    
    const provider = new ethers.providers.JsonRpcProvider("https://rinkeby.infura.io/v3/1c053d661a0c47898fac99771f241f1b")
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, provider)
    const data = await marketContract.fetchMarketItems()
    
    const items = await Promise.all(data.map(async i => {
      const tokenUri = await tokenContract.tokenURI(i.tokenId)
      const meta = await axios.get(tokenUri)
      let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
      let item = {
        price,
        itemId: i.itemId.toNumber(),
        seller: i.seller,
        owner: i.owner,
        image: meta.data.image,
        name: meta.data.name,
        description: meta.data.description,
      }
      return item
    }))
    setNfts(items)
    setLoadingState('loaded') 
  }
  async function buyNft(nft) {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)

    const price = ethers.utils.parseUnits(nft.price.toString(), 'ether')
    const transaction = await contract.createMarketSale(nftaddress, nft.itemId, {
      value: price
    })
    await transaction.wait()
    loadNFTs()
  }
  if(loadingState === 'not-loaded') return <Loader />
  else if (loadingState === 'loaded' && !nfts.length) {
    return (
      <div style={{ textAlign: 'center' , alignItems: 'center' , width:"100%"}}>
        <Typography.Title level={3} style={{color: 'grey', textAlign: 'center' }}>
          当前没有NFT资产可买，请先到 
          <Link to="/create-nft">
            &nbsp;创建NFT资产.
          </Link> <br />
          开启您的NFT创作交易之旅！
        </Typography.Title>
        <Image
           width={'100%'}
          src="https://gateway.pinata.cloud/ipfs/QmdN4iTJibznDwBgNbB3CxE1hGZYWLCDYfDAW3tkPwxhrx"
        />
      </div>
    )
  }
  return (
    <>
      <Space size="large" wrap style={{justifyContent: 'center' }}>
        {
          nfts.map((nft, i) => (
            <Card
              key={i}
              hoverable
              style={{width:"330px", textAlign:'center'}}
              cover={<Image alt="NFT" src={nft.image} style={{height: '330px',width: '100%'}} />}
            >
              <Meta style={{fontWeight:"bold" , height:'160px', overflow: 'hidden' }} title={nft.name} description={nft.description} />
              <Card bordered={false} style={{background: "white" , textAlign: 'center'}}>
                <p style={{color: "grey" , fontSize: 18 , fontWeight:"bold" , textAlign: 'center'}}>{nft.price} ETH</p>
                <Button
                type="primary"
                shape="round"
                icon={<TransactionOutlined />}
                size="large"
                onClick={() => buyNft(nft)}
                >
                  购买
                </Button>
              </Card>
            </Card>
          ))
        }
      </Space>
    </>
  )
}

export default HomePage