import react from 'react';
import ReactDom from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import App from './App';
import 'antd/dist/antd.css';

ReactDom.render(
    <react.StrictMode>
        <Router>
            <App />
        </Router>
    </react.StrictMode>,
    document.getElementById('root'),
)
