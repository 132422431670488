import { Routes, Route, Link } from 'react-router-dom';
import { Layout, Typography, Space } from 'antd';
import { Navbar,HomePage,CreateNFT,MyAsset,DashBoard } from './components';
import './App.css';

const App = () => {
  return (
        <div className='app'>
            <div className='navbar'>
                <Navbar />
            </div>
            <div className="main">
                <Layout>
                    <Typography.Title level={2} type={'success'} className="heading" style={{marginLeft:'20px'}}>METAVERSE NFT</Typography.Title>
                    <div className="routes">
                        <Routes>
                            <Route path='/' element={<HomePage />} />;
                            <Route path='/create-nft' element={<CreateNFT />} />;
                            <Route path='/myasset' element={<MyAsset />} />;
                            <Route path='/dashboard' element={<DashBoard />} />;
                        </Routes>
                    </div>
                </Layout>
                <div className="footer">
                    <Typography.Title level={5} style={{ color: 'white', textAlign: 'center' }}>版权 © 2022 
                        <Link to="/">
                            &nbsp;TZMOS 区块链公司.
                        </Link> <br />
                        版权所有.
                    </Typography.Title>
                    <Space>
                        <Link to="/">主页</Link>
                        <Link to="/create-nft">创建NFT资产</Link>
                        <Link to="/myasset">已购NFT资产</Link>
                        <a href="https://crypto.tzmos.com/">加密世界</a>
                    </Space>
                </div>
            </div>
        </div>
    )
}

export default App